import React from "react"
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet";    
import { graphql } from 'gatsby'
 
const KontatkyPage = ({ data, location}) => {
  const text = data?.scomos

  return (
      <>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Kontakty | Exekutorský úřad Praha 9</title> 
        </Helmet>  
        <Layout location={location} pageContext={{title: 'Kontakty'}} >
          
              <div className="mx-auto w-full  relative z-30  ">
                  <div className="mx-auto max-w-6xl w-full p-8 lg:px-14   z-auto  relative">    
                      

                        <div className="relative    lg:pb-20 pb-20">

 
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-14 lg:gap-6 mb-20">
                            <div className=" placeholder:px-5">
                                 
                                <div   dangerouslySetInnerHTML={{__html: text?.adresa?.content}}>
                                    
                                </div>
                            </div> 
                            <div className=" px-5 text-xl" >
                                     <div   dangerouslySetInnerHTML={{__html: text?.telefon?.content}}>
                                    
                                    </div>
                            </div> 
                            <div className="  px-5">
                                     <div   dangerouslySetInnerHTML={{__html: text?.hodiny?.content}}>
                                    
                                    </div>
                            </div> 
                        </div>     
                          <div dangerouslySetInnerHTML={{__html: text?.page?.content}} className="mt-6 prose max-w-6xl   text-gray-600 mx-auto pb-10"> 
                          
                            </div> 
                        </div>
                       
                    </div>

                  </div>
        </Layout>
      </>

  )
}
 
export default KontatkyPage


export const query = graphql`
  query Kontakty {
    scomos {
      adresa: pageItemFindOne(id:125) {
          content
      },
      telefon: pageItemFindOne(id:127) {
          content
      },
      hodiny: pageItemFindOne(id:126) {
          content
      },
      page: pageFindOneByUrl(url: "kontakty/") {
        content
      },
    }
  }
` 